import { perfTracker } from './performance.js';
import { loadProject } from './project.js';
export class Player {
    state;
    info;
    resourceRoot;
    basePath;
    project;
    console;
    container;
    resizeObserver;
    _thumbnailMode = false;
    constructor(state, info, resourceRoot, basePath, console, containerOrId) {
        this.state = state;
        this.info = info;
        this.resourceRoot = resourceRoot;
        this.basePath = basePath;
        this.console = console ?? window.console;
        if (typeof containerOrId === 'string') {
            this.container = document.getElementById(containerOrId);
        }
        if (containerOrId !== undefined) {
            this.container = containerOrId;
        }
        else {
            this.container = document.body;
        }
        // Resize the project as its container resizes.
        // TODO: verify this supports ideal updating on resize
        // Called after layout but before paint.
        this.resizeObserver = new ResizeObserver((entries) => {
            for (const entry of entries) {
                if (entry.target === this.container) {
                    this.setProjectDimensions(entry.contentRect);
                }
            }
        });
        this.resizeObserver.observe(this.container);
    }
    set thumbnailMode(value) {
        this._thumbnailMode = value;
    }
    // Resize project to fill container.
    setProjectDimensions(rect) {
        if (!this.project) {
            return;
        }
        const { x, y, width, height } = rect;
        if (x !== this.project.viewportX ||
            y !== this.project.viewportY ||
            width !== this.project.viewportWidth ||
            height !== this.project.viewportHeight) {
            this.project.viewportX = x;
            this.project.viewportY = y;
            this.project.viewportWidth = width;
            this.project.viewportHeight = height;
        }
    }
    async asyncInitialize(playkit) {
        perfTracker.startMark('load');
        const viewportDimensions = this.container.getBoundingClientRect();
        const project = await loadProject({
            state: this.state,
            info: this.info,
            resourceRoot: this.resourceRoot,
            basePath: this.basePath,
            playkit,
        });
        perfTracker.endMark('load');
        if (this._thumbnailMode) {
            project.thumbnailMode = true;
        }
        this.project = project;
        this.setProjectDimensions(viewportDimensions);
        try {
            perfTracker.startMark('project-mount');
            project.mount?.(this.container);
            perfTracker.endMark('project-mount');
        }
        catch (err) { }
    }
    run(maxTicks) {
        this.project.run(maxTicks);
    }
    dispose() {
        this.resizeObserver.disconnect();
        try {
            this.project?.dispose(); // dispose unmounts
        }
        catch (err) { }
    }
}
