var __jsx = React.createElement;
import { Badge, ListItem, UnorderedList, VStack } from '@chakra-ui/react';
import { getConfig } from '@playful/config';
import { Box, Button, HStack, Heading, Switch, Text } from '@playful/design_system';
import { projectDesignState } from '../workbench/project/projectDesignState';
import React, { useState } from 'react';
import { useSnapshot } from 'valtio';
import { redirectToManageSubscription } from '../user/stripeRedirects';
import { useUserContext } from '../user/UserContext';
export var PricingChoosePlan = function PricingChoosePlan(_ref) {
  var handleSelectProduct = _ref.handleSelectProduct,
    handleClickFreePlan = _ref.handleClickFreePlan,
    stripePriceInfo = _ref.priceInfo;
  var _getConfig = getConfig(),
    stripeYearlyPlan = _getConfig.stripeYearlyPlan,
    stripeMonthlyPlan = _getConfig.stripeMonthlyPlan;
  var _useSnapshot = useSnapshot(projectDesignState),
    dirtyCount = _useSnapshot.dirtyCount;
  var _useUserContext = useUserContext(),
    isLoggedInUser = _useUserContext.isLoggedInUser,
    user = _useUserContext.user,
    hasActiveSubscription = _useUserContext.hasActiveSubscription;
  var _useState = useState(false),
    isYearly = _useState[0],
    setIsYearly = _useState[1];

  // If price info does not load, using as default
  var defaultPriceInfo = {
    monthlyPrice: 9,
    yearlyPrice: 108,
    annualSavingsPercent: 0
  };
  var priceInfo = stripePriceInfo !== null && stripePriceInfo !== void 0 ? stripePriceInfo : defaultPriceInfo;
  var monthlyPrice = priceInfo.monthlyPrice,
    yearlyPrice = priceInfo.yearlyPrice,
    annualSavingsPercent = priceInfo.annualSavingsPercent;
  var proPrice = isYearly ? yearlyPrice : monthlyPrice;
  var handleProClick = function handleProClick() {
    // short circuit and redirect pro users to manage subscription
    if (hasActiveSubscription) {
      if (user.stripeCustomerId === undefined) return;
      redirectToManageSubscription(user.stripeCustomerId, user.id);
      return;
    }
    var plan = isYearly ? stripeYearlyPlan : stripeMonthlyPlan;
    handleSelectProduct(plan);
  };
  var currency = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 0
  });
  var upgradeOrRenewText = !!user.subscriptionStatus ? 'renew subscription' : 'upgrade to pro';
  return __jsx(React.Fragment, null, __jsx(Heading, {
    size: "lg",
    textAlign: {
      base: 'left',
      lg: 'center'
    },
    py: 5,
    pl: {
      base: 10,
      lg: 0
    }
  }, "choose a plan"), __jsx(Box, {
    display: "flex",
    justifyContent: {
      base: 'flex-start',
      lg: 'center'
    },
    pl: {
      base: 10,
      lg: 0
    },
    mb: 10
  }, __jsx(HStack, {
    spacing: 3
  }, __jsx(Text, {
    fontWeight: "medium"
  }, "monthly"), __jsx(Switch, {
    size: "lg",
    colorScheme: 'purple',
    isChecked: isYearly,
    onChange: function onChange(e) {
      return setIsYearly(e.target.checked);
    }
  }), __jsx(Box, {
    display: "flex",
    position: "relative"
  }, __jsx(Text, {
    fontWeight: "medium",
    mr: 2
  }, "yearly"), annualSavingsPercent ? __jsx(Badge, {
    colorScheme: "purple",
    size: "sm"
  }, "save ", annualSavingsPercent, "%") : undefined))), __jsx(Box, {
    display: "flex",
    w: "100%",
    justifyContent: "space-between",
    flexWrap: "wrap-reverse"
  }, __jsx(Box, {
    flex: 1,
    p: 10,
    w: {
      base: '100%',
      lg: 'auto'
    }
  }, __jsx(VStack, {
    spacing: 4,
    align: "left"
  }, __jsx(Text, {
    fontSize: "lg",
    fontWeight: "bold"
  }, "Hatch Free"), __jsx(Box, null, __jsx(Heading, {
    size: "2xl"
  }, currency.format(0))), __jsx(UnorderedList, {
    style: {
      marginLeft: '1em'
    },
    fontSize: "lg",
    stylePosition: "outside"
  }, __jsx(ListItem, null, "Hatch hosting & subdomain", __jsx("div", null, "(yourname.hatch.one)")), __jsx(ListItem, null, "Hatch watermark"), __jsx(ListItem, null, "3 published projects"), __jsx(ListItem, null, "3 pages per project"), __jsx(ListItem, null, "Unlimited community projects"), __jsx(ListItem, null, "2GB media storage"), __jsx(ListItem, null, "Standard customer support")), !hasActiveSubscription && __jsx(Box, null, __jsx(Button, {
    size: "lg",
    display: "inline",
    onClick: handleClickFreePlan
  }, !isLoggedInUser ? 'sign up' : 'stick with free')))), __jsx(Box, {
    flex: 1,
    w: {
      base: '100%',
      lg: 'auto'
    },
    p: 10,
    backgroundColor: "purple.600",
    borderRadius: {
      base: 0,
      lg: 'lg'
    },
    boxShadow: "lg",
    color: "white"
  }, __jsx(VStack, {
    spacing: 4,
    align: "left"
  }, __jsx(Text, {
    fontSize: "lg",
    fontWeight: "bold"
  }, "Hatch Pro (early bird pricing)"), __jsx(Box, {
    display: "flex",
    alignItems: "center"
  }, proPrice && __jsx(React.Fragment, null, __jsx(Heading, {
    size: "2xl",
    mr: 2
  }, currency.format(proPrice), " ", __jsx("s", null, currency.format(isYearly ? 156 : 13))), __jsx(Box, {
    display: "flex",
    flexDirection: "column"
  }, __jsx(Text, {
    fontSize: "xs"
  }, "per ", isYearly ? 'year' : 'month')))), __jsx(UnorderedList, {
    style: {
      marginLeft: '1em'
    },
    fontSize: "lg",
    stylePosition: "outside"
  }, __jsx(ListItem, null, "Hatch hosting"), __jsx(ListItem, null, "Free custom domain name"), __jsx(ListItem, null, "Bring your own domain"), __jsx(ListItem, null, "Removable Hatch watermark"), __jsx(ListItem, null, "Add your Google Analytics ID"), __jsx(ListItem, null, "Add site title, meta description"), __jsx(ListItem, null, "Set search engine visibility"), __jsx(ListItem, null, "Add password protection"), __jsx(ListItem, null, "20 published projects"), __jsx(ListItem, null, "Unlimited pages per project"), __jsx(ListItem, null, "Unlimited community projects"), __jsx(ListItem, null, "10GB media storage"), __jsx(ListItem, null, "Priority customer support")), __jsx(Box, null, __jsx(Button, {
    display: "inline-block",
    variant: "solid",
    size: "lg",
    backgroundColor: "yellow.500",
    _hover: {
      backgroundColor: 'yellow.200'
    },
    _active: {
      backgroundColor: 'yellow.300'
    },
    onClick: handleProClick,
    isLoading: dirtyCount !== 0
  }, hasActiveSubscription ? 'manage subscription' : upgradeOrRenewText))))));
};