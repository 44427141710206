export function forEachView(view, callback) {
    callback(view);
    if (view.children) {
        for (const child of view.children) {
            forEachView(child, callback);
        }
    }
}
// recursive reducing of a view
export function reduceView(view, callback, acc) {
    const ret = callback(acc, view);
    if (view.children) {
        for (const child of view.children) {
            reduceView(child, callback, ret);
        }
    }
    return acc;
}
export const PAGE_MIN_SIZE = 100;
